.home-page{
    background: linear-gradient(to bottom right, rgb(157, 20, 220),black);
    color:white;
    padding:20px 20px;
    min-height: 76vh;
}

.inner-body{
    margin-top:20vh;
}


.heading{
    font-family: font1;
    
}