.Page{
    background: linear-gradient(to top right, rgb(157, 20, 220),black);
    height:100vh; 
    overflow-y: scroll;   
    color:white;    
}

.Page h3{
    color:white;
    font-family: font1;    
}

.Page>.Login{
    margin-top:1vh;
    margin-left:10%;
    height:79vh;
    width:80%;
    background: linear-gradient(
        to bottom right, 
        rgba(0,0,0,0.7), 
        rgba(0,0,0,0.3)
    ) ;
    border-radius: 9px;
    z-index: 1;
    backdrop-filter: blur(2px);
    color:white;
    display: flex;
    flex-direction: column;     
}

.Page>.Login>.box{
    width:100%;  
    padding: 0 0;    
    display: flex;
    align-self: center;
}

.Page>.Login .form-container{
    flex:1;   
    height:76vh;     
    padding-top: 10px;    
    background: linear-gradient(
        to bottom right, 
        rgba(0,0,0,0.4), 
        rgba(0,0,0,0.3)
    ) ;
    border-radius: 5px;
    z-index: 2;
    backdrop-filter: blur(2rem);
    padding-top:20vh
}

.Page>.Login .desktop-only{
    flex:1;   
    height:76vh;     
    padding-top: 10px;    
    background: linear-gradient(
        to bottom right, 
        rgba(255,255,255,0.4), 
        rgba(255,255,255,0.3)
    ) ;
    border-radius: 5px;
    z-index: 2;
    backdrop-filter: blur(3rem);
}

.Page>.Login .desktop-only p{
    line-height: 30px;
}


.Page>.Login .desktop-only img{
    display:flex;
    max-width:70%;
    justify-self: center;
    align-self: center;
    align-content: center;
    margin-left:10%;    
}

#loginForm>div>input, #signupForm>div>input{
    background: linear-gradient(
        to bottom right, 
        rgba(76, 3, 99, 0.4),
        rgba(39, 2, 43, 0.3)
    ) ;
    border-top:none;
    border-left: none;
    border-right: none;
    color:white;
    backdrop-filter: blur(3rem);
    height:7vh;
}

#loginForm>div>input::placeholder, #signupForm>div>input::placeholder{
    color:white;
    font-size:larger;
}

#loginForm>div>input:focus, #signupForm>div>input:focus{
    background: linear-gradient(
        to bottom right, 
        rgba(194, 37, 241, 0.4),
        rgba(221, 22, 243, 0.3)
    ) ;
    color:white;
    border-top:none;
    border-left: none;
    border-right: none;    
}

.btn-primary{    
    background-color: blueviolet;    
    border-color: blueviolet;
}


.btn-primary:focus{
    background-color: blueviolet;    
    border-color: blueviolet;
}

.btn-primary:hover{
    border-color: blueviolet;
    background-color: rgb(91, 16, 161);
}


.mobile-only{
    display:none;
}

@media screen and (max-width:1000px) {
    .desktop-only{
        display:none;
    }
    .mobile-only{
        display: block;
    }
}


/*ACCOUNT SETION*/
.Page>.Account{
    width:96%;
    height:96vh;
    background: linear-gradient(
        to bottom right, 
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0.7)
    ) ;
    margin-left:2%;
    margin-top:2vh;
    backdrop-filter: blur;
    color:black;
    text-align: left;
}   

/* width */
.Page>.Account::-webkit-scrollbar {
    width: 5px;
    background-color:rgb(175, 40, 238);
}
     
/* Track */
.Page>.Account::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white;
    border-radius: 10px;
}
      
/* Handle */
.Page>.Account::-webkit-scrollbar-thumb {
    background: rgb(90, 4, 129);
    border-radius: 10px;
}


.scrollable{
    overflow-y: scroll;
}

/* width */
.scrollable::-webkit-scrollbar {
    width: 5px;
    background-color:rgb(175, 40, 238);
}
     
/* Track */
.scrollable::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white;
    border-radius: 10px;
}
      
/* Handle */
.scrollable::-webkit-scrollbar-thumb {
    background: rgb(90, 4, 129);
    border-radius: 10px;
}

.pagination{
    overflow-x: scroll;
}

/* width */
.pagination::-webkit-scrollbar {
    width: 1px;
    height:2px;
    background-color:rgb(175, 40, 238);
}
     
/* Track */
.pagination::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px white;
    border-radius: 1px;
}
      
/* Handle */
.pagination::-webkit-scrollbar-thumb {
    background: rgb(90, 4, 129);
    border-radius: 1px;
}

.table-primary{
    border-color:rgb(182, 4, 182);
    
}


.table-primary>tbody>tr>td{
    background-color: rgb(231, 79, 231);
}


.table-primary>thead>th{
    background-color: rgb(94, 8, 94);    
    color:white;
    font-size: larger;
}

#customize-input{
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom:solid thin purple;
}

#customize-input:focus{
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom:solid thin purple;
}


/* width */
.Page::-webkit-scrollbar {
    width: 5px;
    background-color:rgb(175, 40, 238);
}
     
/* Track */
.Page::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white;
    border-radius: 10px;
}
      
/* Handle */
.Page::-webkit-scrollbar-thumb {
    background: rgb(90, 4, 129);
    border-radius: 10px;
}
