.body{
    height:76vh;
    background: linear-gradient(to top right, rgb(157, 20, 220),black);
    
}

h3{
    color:white;
    font-family: font1;
}

p{
    text-align: left;
    color:white;    
}

p a{
    color:white;
    text-decoration: none;
}


p a:hover{
    color:white;
    text-decoration: underline;
}