.footer-body{
    background: linear-gradient(to top right, rgb(157, 20, 220),black);
    color:white;
    height:24vh;
    text-align: left;
    padding:20px;
}

.footer-body a{
    color:white;
    text-decoration: none;
}

.footer-body a:hover{
    color:rgb(173, 172, 172);
    text-decoration: underline;
}

#built{
    position:fixed;
    bottom:0;    
    right:0;
}