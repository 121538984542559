@font-face{
    font-family:font1;
    src: url(../assets/fonts/ThisIsWhoWeAre-2y0o.ttf);
}

@font-face{
    font-family:font2;
    src: url(../assets/fonts/BladeAlexander-Gp8A.ttf);
}

@font-face{
    font-family:font3;
    src: url(../assets/fonts/KGHAPPY.ttf);
}

@font-face{
    font-family:font4;
    src: url(../assets/fonts/KGHAPPYShadows.ttf);
}

@font-face{
    font-family:font5;
    src: url(../assets/fonts/KGHAPPYSolid.ttf);
}

@font-face{
    font-family:font6;
    src: url(../assets/fonts/YouthFuryPersonalUse-zMea.ttf);
}

@font-face{
    font-family:font7;
    src: url(../assets/fonts/themify.ttf);
}

@font-face{
    font-family:font8;
    src: url(../assets/fonts/gitchgitch.otf);
}

@font-face{
    font-family:font8italics;
    src: url(../assets/fonts/gitchgitch-italic.otf);
}

@font-face{
    font-family:font8bolditalics;
    src: url(../assets/fonts/gitchgitch-Bold-Italic.otf);
}

@font-face{
    font-family:font8bold;
    src: url(../assets/fonts/gitchgitch-Bold.otf);
}

@font-face{
    font-family:font8hollow;
    src: url(../assets/fonts/gitchgitch-Hollow.otf);
}

@font-face{
    font-family:font8hollowinverse;
    src: url(../assets/fonts/gitchgitch-Hollow-inverse.otf);
}

@font-face{
    font-family:font8inverse;
    src: url(../assets/fonts/gitchgitch-inverse.otf);
}

@font-face{
    font-family:font9;
    src: url(../assets/fonts/Graff-pDGa.ttf);
}

@font-face{
    font-family:font10;
    src: url(../assets/fonts/Bubble-yE62.ttf);
}

@font-face{
    font-family:font11;
    src: url(../assets/fonts/gitchgitch-italic.otf);
}

@font-face{
    font-family:font12;
    src: url(../assets/fonts/HorizonOutlinetwo-8K02.otf);
}

@font-face{
    font-family:font13;
    src: url(../assets/fonts/StartWithToday-W9jG.ttf);
}








